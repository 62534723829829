import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: ".top-message" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :200
})
// .addIndicators()
.addTo(controller);

scene_hero.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.l-header__nav').addClass('is-show');
});
scene_hero.on('leave', ()=>{
//triggerElementを抜けたとき
$('.l-header__nav').removeClass('is-show');
});
